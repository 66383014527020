<template>
  <main-layout pageTitle="Leaderboard">
    <leaderboard :show-stats="false" v-if="leagueId" :league-id="leagueId" />

    <awards />

    <comments-list v-if="leagueId" :leagueId="leagueId" />
  </main-layout>
</template>

<script>
// import axios from "axios";
import leagueModule from "../modules/leagues";
import Leaderboard from "@/components/Leaderboard.vue";
import Awards from "@/components/Awards.vue";
import CommentsList from "@/components/leagues/CommentsList.vue";

export default {
  components: { Leaderboard, Awards, CommentsList },
  data: () => ({
    leagueId: null,
  }),
  watch: {
    $route() {
      this.leagueId = this.$route.params.league_id;
    },
  },
  async mounted() {
    this.leagueId = this.$route.params.league_id;
    leagueModule.setCurrentLeague(this.leagueId);
  },
};
</script>
