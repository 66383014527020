<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>ACHIEVEMENTS</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <div class="row">
        <div class="col-3 p-1 mb-1" v-for="badge of badges" :key="badge.id">
          <img
            :src="
              hasBadge(badge.id)
                ? `/assets/badges/${badge.icon}.svg`
                : '/assets/badges/none.svg'
            "
            :title="getAltText(badge)"
            @click="showNotif(badge)"
            :alt="badge.icon"
            class="img-fluid"
          />
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
import axios from "axios";
export default {
  data: () => ({
    badges: [],
    userBadges: [],
  }),
  components: { IonCard, IonCardContent, IonCardHeader, IonCardTitle },
  methods: {
    hasBadge(id) {
      return this.userBadges.includes(id);
    },
    getAltText(badge) {
      return `${badge.name}${
        badge.description ? ": " + badge.description : ""
      }`;
    },
    showNotif(badge) {
      let text = this.getAltText(badge);
      this.$notify({
        type: "info",
        text,
      });
    },
  },
  mounted() {
    axios.get("user/badges").then((response) => {
      this.badges = response.data.badges;
      this.userBadges = response.data.user_badge_ids;
    });
  },
};
</script>
