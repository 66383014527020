<template>
  <div class="media p-3 border-bottom">
    <div class="media-body p-0">
      <span class="mt-0"
        ><strong>{{
          comment.commenter
            ? comment.commenter.display_name
            : comment.guest_name
        }}</strong>
        <small class="text-muted"
          >- {{ formatAsDiff(comment.created_at) }}</small
        ></span
      >

      <!-- <div class="mt-1">{!! $markdown->line() !!}</div> -->
      <div class="mt-1">
        {{ comment.comment }}
      </div>

      <div class="mt-1 pb-3 d-block">
        <div class="float-right">
          <!-- @can('reply-to-comment', $comment)
            <a
              href="#"
              data-toggle="modal"
              data-target="#reply-modal-{{ $comment->id }}"
              style="font-size: 0.75rem"
              >Reply</a
            >
            @endcan
            @can('edit-comment', $comment)
            <a
              href="#"
              data-toggle="modal"
              data-target="#comment-modal-{{ $comment->id }}"
              style="font-size: 0.75rem"
              >Edit</a
            >
            @endcan
            @can('delete-comment', $comment)
            <a
              href="{{ url('comments/' . $comment->id) }}"
              onclick="event.preventDefault();document.getElementById('comment-delete-form-{{ comment.id }}').submit();"
              style="font-size: 0.75rem"
              >Delete</a
            >
            @endcan -->
        </div>
      </div>

      <!-- @can('edit-comment', $comment)
        <div
          class="modal fade"
          id="comment-modal-{{ $comment->id }}"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <form
                method="POST"
                action="{{ url('comments/' . $comment->id) }}"
              >
                @method('PUT') @csrf
                <div class="modal-header">
                  <h5 class="modal-title">Edit Comment</h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="message">Update your message here:</label>
                    <textarea
                      required
                      class="form-control"
                      name="message"
                      rows="3"
                      >{{ $comment->comment }}</textarea
                    >
                    <small class="form-text text-muted"
                      ><a
                        target="_blank"
                        href="https://help.github.com/articles/basic-writing-and-formatting-syntax"
                        >Markdown</a
                      >
                      cheatsheet.</small
                    >
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary text-uppercase"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-outline-success text-uppercase"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        @endcan -->

      <!-- @can('reply-to-comment', $comment)
        <div
          class="modal fade"
          id="reply-modal-{{ $comment->id }}"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <form
                method="POST"
                action="{{ url('comments/' . $comment->id) }}"
              >
                @csrf
                <div class="modal-header">
                  <h5 class="modal-title">Reply to Comment</h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="message">Enter your message here:</label>
                    <textarea
                      required
                      class="form-control"
                      name="message"
                      rows="3"
                    ></textarea>
                    <small class="form-text text-muted"
                      ><a
                        target="_blank"
                        href="https://help.github.com/articles/basic-writing-and-formatting-syntax"
                        >Markdown</a
                      >
                      cheatsheet.</small
                    >
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary text-uppercase"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-outline-success text-uppercase"
                  >
                    Reply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        @endcan -->

      <div v-if="comments[comment.id]">
        <div v-for="child of comments[comment.id]" :key="child.id">
          <comment :comment="child" :comments="comments" />
        </div>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>
</template>

<script>
import moment from "moment";
export default {
  props: ["comment", "comments"],
  methods: {
    formatAsDiff(timestamp) {
      return moment(timestamp).fromNow();
    },
  },
};
</script>
