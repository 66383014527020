<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>LEAGUE COMMENTS</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <div v-if="comments && comments.length == 0" class="alert alert-warning">
        There are no comments yet.
      </div>
      <div v-else-if="comments">
        <div class="card">
          <div v-for="(commentGroup, key) of comments" :key="key">
            <comment
              v-for="comment of commentGroup"
              :key="comment.id"
              :comment="comment"
              :comments="comments"
            />
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import axios from "axios";
import Comment from "./Comment.vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
export default {
  components: { Comment, IonCard, IonCardContent, IonCardHeader, IonCardTitle },
  data: () => ({
    comments: null,
  }),
  props: ["leagueId"],
  async mounted() {
    this.comments = (
      await axios.get("/league/" + this.leagueId + "/comments")
    ).data;
  },
};
</script>
